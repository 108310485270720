<template>
  <div class="col-12 col-xl-2">
    <div class="list-group" id="list-tab" role="tablist">
      <a
        class="list-group-item list-group-item-action active quicksand"
        id="list-sandwiches-list"
        data-bs-toggle="list"
        href="#list-sandwiches"
        role="tab"
        aria-controls="list-sandwich"
      >
        <i class="me-1">
          <img
            class="img-fluid"
            :src="require('@/assets/img/fotos_menu/SANDWICH.png')"
        /></i>
        Sándwiches
      </a>
      <a
        class="list-group-item list-group-item-action quicksand"
        id="list-hotCakes-list"
        data-bs-toggle="list"
        href="#list-hotCakes"
        role="tab"
        aria-controls="list-hotCakes"
        ><i class="me-1"
          ><img
            class="img-fluid"
            :src="require('@/assets/img/fotos_menu/HOTCAKES.png')"
        /></i>
        Hot Cakes</a
      >
      <a
        class="list-group-item list-group-item-action quicksand"
        id="list-avena-list"
        data-bs-toggle="list"
        href="#list-avena"
        role="tab"
        aria-controls="list-avena"
        ><i class="me-1"
          ><img :src="require('@/assets/img/fotos_menu/AVENA.png')"
        /></i>
        Avena Imperial</a
      >
      <a
        class="list-group-item list-group-item-action quicksand"
        id="list-omelettes-list"
        data-bs-toggle="list"
        href="#list-omelettes"
        role="tab"
        aria-controls="list-omelettes"
        ><i class="me-1"
          ><img
            class="img-fluid"
            :src="require('@/assets/img/fotos_menu/OMELETTE.png')"
            width="50px" /></i
        >Omelettes</a
      >
      <a
        class="list-group-item list-group-item-action quicksand"
        id="list-ensaladas-list"
        data-bs-toggle="list"
        href="#list-ensaladas"
        role="tab"
        aria-controls="list-ensalada"
        ><i class="me-1"
          ><img :src="require('@/assets/img/fotos_menu/ENSALADA.png')"
        /></i>
        Ensaladas</a
      >
      <a
        class="list-group-item list-group-item-action quicksand"
        id="list-platosFuertes-list"
        data-bs-toggle="list"
        href="#list-platosFuertes"
        role="tab"
        aria-controls="list-platosFuertes"
        ><i class="me-1"
          ><img
            class="img-fluid"
            :src="require('@/assets/img/fotos_menu/PLATO FUERTE.png')"
        /></i>
        Platos fuertes</a
      >
      <a
        class="list-group-item list-group-item-action quicksand"
        id="list-bebidas-list"
        data-bs-toggle="list"
        href="#list-bebidas"
        role="tab"
        aria-controls="list-bebidas"
        ><i class="me-1"
          ><img
            class="img-fluid"
            :src="require('@/assets/img/fotos_menu/BEBIDA.png')" /></i
        >Bebidas</a
      >
    </div>
  </div>
  <!--Sandwich-->
  <div class="col-12 col-xl-10">
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="list-sandwiches"
        role="tabpanel"
        aria-labelledby="list-sandwiches-list"
      >
        <div class="descripcionCorta quicksand mb-2">
          <span>Acompañado con ensalada mixta.</span>
        </div>
        <swiper
          :slides-per-view="3"
          :navigation="true"
          :space-between="30"
          :pagination="true"
        >
          <swiper-slide v-for="item in urlImagenSandwich">
            <div class="contenedor_img">
              <div class="tituloImagen roboto">
                {{ item.nombre }}
              </div>

              <img :src="urlFotos + item.foto" />

              <div class="overlay">
                <div class="descripcion">
                  {{ item.descripcion }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!--Hotcake-->
      <div
        class="tab-pane fade"
        id="list-hotCakes"
        role="tabpanel"
        aria-labelledby="list-hotCakes-list"
      >
        <swiper
          :slides-per-view="3"
          :navigation="true"
          :space-between="30"
          :pagination="true"
        >
          <swiper-slide v-for="item in urlImagenHotcake">
            <div class="contenedor_img">
              <div class="tituloImagen roboto">
                {{ item.nombre }}
              </div>

              <img :src="urlFotos + item.foto" />

              <div class="overlay">
                <div class="descripcion">
                  {{ item.descripcion }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!--Avena-->
      <div
        class="tab-pane fade"
        id="list-avena"
        role="tabpanel"
        aria-labelledby="list-avena-list"
      >
        <swiper
          :slides-per-view="3"
          :navigation="true"
          :space-between="30"
          :pagination="true"
        >
          <swiper-slide v-for="item in urlImagenAvena">
            <div class="contenedor_img">
              <div class="tituloImagen roboto">
                {{ item.nombre }}
              </div>

              <img :src="urlFotos + item.foto" />

              <div class="overlay">
                <div class="descripcion">
                  {{ item.descripcion }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!--Omelettes-->
      <div
        class="tab-pane fade"
        id="list-omelettes"
        role="tabpanel"
        aria-labelledby="list-omelettes-list"
      >
        <div class="descripcionCorta quicksand mb-2">
          Puré de camote o papa, arroz blanco o
          integral y espaguetti regular o integral. 
        </div>
        <swiper
          :slides-per-view="3"
          :navigation="true"
          :space-between="30"
          :pagination="true"
        >
          <swiper-slide v-for="item in urlImagenOmelette">
            <div class="contenedor_img">
              <div class="tituloImagen roboto">
                {{ item.nombre }}
              </div>

              <img :src="urlFotos + item.foto" />

              <div class="overlay">
                <div class="descripcion">
                  {{ item.descripcion }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!--fin swipper-->
      </div>

      <!--Ensalada-->
      <div
        class="tab-pane fade"
        id="list-ensaladas"
        role="tabpanel"
        aria-labelledby="list-ensaladas-list"
      >
        <swiper
          :slides-per-view="3"
          :navigation="true"
          :space-between="30"
          :pagination="true"
        >
          <swiper-slide v-for="item in urlImagenEnsalada">
            <div class="contenedor_img">
              <div class="tituloImagen roboto">
                {{ item.nombre }}
              </div>

              <img :src="urlFotos + item.foto" />

              <div class="overlay">
                <div class="descripcion">
                  {{ item.descripcion }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!--Platos fuertes-->
      <div
        class="tab-pane fade"
        id="list-platosFuertes"
        role="tabpanel"
        aria-labelledby="list-platosFuertes-list"
      >
        <div class="descripcionCorta quicksand mb-2">
          Un complemento a elegir: puré de camote o papa, arroz blanco o
          integral y espaguetti regular o integral. 
        </div>
        <swiper
          :slides-per-view="3"
          :navigation="true"
          :space-between="30"
          :pagination="true"
        >
          <swiper-slide v-for="item in urlImagenPlatoFuerte">
            <div class="contenedor_img">
              <div class="tituloImagen roboto">
                {{ item.nombre }}
              </div>

              <img :src="urlFotos + item.foto" />

              <div class="overlay">
                <div class="descripcion">
                  {{ item.descripcion }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <!--Bebida-->
      <div
        class="tab-pane fade"
        id="list-bebidas"
        role="tabpanel"
        aria-labelledby="list-bebidas-list"
      >
        <swiper
          :slides-per-view="3"
          :navigation="true"
          :space-between="30"
          :pagination="true"
        >
          <swiper-slide v-for="item in urlImagenBebida">
            <div class="contenedor_img">
              <div class="tituloImagen roboto">
                {{ item.nombre }}
              </div>

              <img :src="urlFotos + item.foto" />

              <div class="overlay">
                <div class="descripcion">
                  {{ item.descripcion }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination } from "swiper";

SwiperCore.use([Navigation, Pagination]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      urlFotos: "https://187.188.16.29:4431/paginas/fitbar/",
      overlay: "",
      descripcion: "",
      tituloMenu: "",
      descripcionMenu: "",
    };
  },
  name: "menu_fitbar",

  computed: {
    ...mapState([
      "urlImagenSandwich",
      "urlImagenHotcake",
      "urlImagenAvena",
      "urlImagenPlatoFuerte",
      "urlImagenOmelette",
      "urlImagenEnsalada",
      "urlImagenBebida",
    ]),
  },
  methods: {
    ...mapMutations(["getFotos"]),
    ...mapActions(["obtenerImagenes"]),
  },
  created() {
    this.$store.dispatch("obtenerImagenes");
  },
};
</script>
<style scoped>
.tituloImagen {
  font-size: 16px;
}
.descripcionCorta {
  font-size: 1.15rem;
}

.list-group a {
  font-size: 0.79rem;
}
.list-group img {
  width: 4rem;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .list-group img {
    width: 2rem;
  }
  .list-group a {
    font-size: 14px;
  }

  .tituloImagen {
    font-size: 13px;
  }
  .descripcionCorta {
    font-size: 14px;
  }
}
</style>
